.tool-container[data-v-e0674db2] {
  display: flex;
  flex-wrap: wrap;
}
.tool-container .tool[data-v-e0674db2] {
  margin-right: 8px;
  margin-bottom: 8px;
}
.tool-container .required[data-v-e0674db2] {
  margin-right: 4px;
  color: #f00;
  line-height: 24px;
}
