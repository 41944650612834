.tool-container[data-v-867e1c04] {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.tool-container .tool[data-v-867e1c04] {
  margin: 16px;
  padding: 24px;
}
.tool-container .tool h5[data-v-867e1c04] {
  margin: 0 0 16px 0;
  font-size: 20px;
  font-weight: bold;
}
.tool-container .tool .project-info[data-v-867e1c04] {
  margin: 0;
  font-size: 16px;
  line-height: 2;
  text-indent: 2em;
}
.tool-container .tool img[data-v-867e1c04] {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 30px auto;
}
