.title[data-v-2cbdb5e9] {
  height: var(--page-head-height);
  display: flex;
  align-items: center;
  line-height: var(--page-head-height);
}
.guigu-container[data-v-2cbdb5e9] {
  height: var(--2cbdb5e9-height);
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.guigu-container .main-container[data-v-2cbdb5e9] {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.guigu-container .main-container .sider[data-v-2cbdb5e9] {
  min-width: 50px;
  min-height: 50px;
}
.guigu-container .main-container.horizon[data-v-2cbdb5e9] {
  flex-direction: row;
}
.guigu-container .main-container.horizon .sider[data-v-2cbdb5e9] {
  width: 300px;
}
.guigu-container .main-container.horizon .sider.onlySider[data-v-2cbdb5e9] {
  width: 100%;
}
.guigu-container .main-container.vertical[data-v-2cbdb5e9] {
  flex-direction: column;
}
.guigu-container .main-container.vertical .sider[data-v-2cbdb5e9] {
  height: 300px;
}
.guigu-container .main-container.vertical .sider.onlySider[data-v-2cbdb5e9] {
  height: 100%;
}
.guigu-container .main-container .resizer-container[data-v-2cbdb5e9] {
  position: relative;
  border: 0;
}
.guigu-container .main-container .resizer-container .resizer[data-v-2cbdb5e9] {
  position: absolute;
  z-index: 1;
  background-color: #d9d9d9;
  opacity: 0.5;
}
.guigu-container .main-container .resizer-container.horizon[data-v-2cbdb5e9] {
  padding: 0 4px;
  width: 12px;
  height: 100%;
}
.guigu-container .main-container .resizer-container.horizon .resizer[data-v-2cbdb5e9] {
  width: 2px;
  height: 100%;
  cursor: col-resize;
}
.guigu-container .main-container .resizer-container.vertical[data-v-2cbdb5e9] {
  padding: 4px 0;
  width: 100%;
  height: 12px;
}
.guigu-container .main-container .resizer-container.vertical .resizer[data-v-2cbdb5e9] {
  height: 2px;
  width: 100%;
  cursor: row-resize;
}
.guigu-container .main-container .right[data-v-2cbdb5e9] {
  display: flex;
  flex: 1;
  overflow: auto;
}
.guigu-container .main-container .right.horizon[data-v-2cbdb5e9] {
  flex-direction: row;
}
.guigu-container .main-container .right.horizon .main[data-v-2cbdb5e9] {
  width: 50%;
}
.guigu-container .main-container .right.horizon .main.noDetail[data-v-2cbdb5e9] {
  width: 100%;
}
.guigu-container .main-container .right.horizon .detail[data-v-2cbdb5e9] {
  flex: 1;
  min-width: 150px;
}
.guigu-container .main-container .right.vertical[data-v-2cbdb5e9] {
  flex-direction: column;
}
.guigu-container .main-container .right.vertical .main[data-v-2cbdb5e9] {
  height: 50%;
}
.guigu-container .main-container .right.vertical .main.noDetail[data-v-2cbdb5e9] {
  height: 100%;
}
.guigu-container .main-container .right.vertical .detail[data-v-2cbdb5e9] {
  flex: 1;
  min-height: 150px;
}
.tools[data-v-2cbdb5e9] {
  padding: 8px 0;
}
.small-meta_tools[data-v-2cbdb5e9] {
  display: flex;
  justify-content: space-between;
}
